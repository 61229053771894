import React, {Component} from 'react';
import Header from '../HeaderSection/Header';
import Breadcrumb from '../Blogs/Breadcrumb';
import FooterSection from '../FooterSection/Footer';
import dex from "./dex.png";
import dexSec from "./dex-sec.png";
import {Helmet} from "react-helmet";

class WooooDexSection extends Component {
    render() {
        return (
            <div>
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up"/>
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Helmet>
                        <title>Woooo - DEX (Decentralized Exchange): Unleashing the Power of Possibilities</title>
                        <link rel="canonical" href="https://woooo.world/woooo-dex" />
                    </Helmet>
                    <Header imageData={"/img/logo-white.png"}/>
                    <Breadcrumb title="Woooo DEX (Decentralized Exchange): Unleashing the Power of Possibilities "/>
                    <div style={{
                        width: '100%',
                        maxWidth: '1440px',
                        background: '#fff',
                        borderRadius: 20,
                        position: 'relative',
                        zIndex: 9,
                        margin: "0px auto",
                        marginTop: '-6em',
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column"
                    }}>

                        <div style={{width:'60%'}} className="service-thumb mx-auto mobile-dex-img">
                            <img src={dex} alt="dex" />
                        </div>

                        <h4 style={{textAlign: 'center', marginTop: 40,color:"rgb(32, 166, 232)"}}>Instant Crypto Swaps, Your Way! Trade with speed, security, and a touch of Woooo magic. <br/> Elevate your crypto experience now!
                        </h4>

                        <section style={{background:'#fff'}} className="section service-area bg-gray overflow-hidden ptb_100">
                            <div className="container">
                                <div style={{alignItems:'center'}} className="row justify-content-between">
                                    <div className="col-12 col-lg-8 order-2 order-lg-1">
                                        {/* Service Text */}
                                        <div className="service-text pt-4 pt-lg-0">
                                            <h2 style={{color:"rgb(32, 166, 232)"}} className="text-capitalize mb-2">Woooo Dex</h2>
                                            <p style={{marginTop:20}}>
                                                Elevate Your Crypto Adventure! Immerse yourself in the magic of direct crypto swaps—swift, secure, and sensational.WithWoooo DEX, innovation meets autonomy, bringing you a trading experience like never before. Say goodbye to intermediaries, embrace real-time rates, and step into the future of decentralized excitement. Your crypto journey just got a whole lot more thrilling!
                                            </p>

                                            <h4 style={{marginTop:20}}>
                                                🌐 Seamless Trading Experience
                                            </h4>
                                            <p>
                                                Experience the future of peer-to-peer transactions with Woooo DEX. Trade directly through intelligent smart contracts, ensuring a seamless and secure exchange process.
                                            </p>


                                            <h4 style={{marginTop:20}}>
                                                💡 Total Asset Control
                                            </h4>
                                            <p>
                                                Take charge of your financial destiny! Woooo DEX empowers you with complete control over your assets, as you trade directly from your wallet without any intermediaries.
                                            </p>


                                            <h4 style={{marginTop:20}}>
                                                🤝 Trustless Environment
                                            </h4>
                                            <p>
                                                Embrace trustlessness! Our DEX operates on the principles of decentralization, eliminating the need for trust in third parties. Your security is our priority.
                                            </p>

                                            <h4 style={{marginTop:20}}>
                                                🌈 Contributing to Woooo Ecosystem
                                            </h4>
                                            <p>
                                                🌈 Contributing to Woooo Ecosystem
                                            </p>

                                            <h3 style={{marginTop:20,color:"rgb(32, 166, 232)"}}>
                                                Embark on a journey where every trade is an opportunity, and every transaction is a step towards a decentralized future. Woooo DEX - Where Innovation Meets Trading Brilliance!
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 order-1 order-lg-2 d-none d-lg-block get-start-image">
                                        {/* Service Thumb */}
                                        <div style={{width:250}} className="service-thumb mx-auto">
                                            <img src={dexSec} alt="dex" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>


                    </div>
                    <FooterSection/>
                </div>
            </div>
        );
    }
}

export default WooooDexSection;
