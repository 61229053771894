import React, { Component } from 'react';
import Lottie from "lottie-react";
import Animation1 from "./assets/animation.json";
import Animation2 from "./assets/animation_lm2m7czn.json";
import { logEvent } from "firebase/analytics";
import { analytics } from '../../utils/firebaseConfig'



const initData = {
    heading: "Chat, Call, Meet and Mine Coins With Realtime Translation",
    headingTwo: "Dive into a world of possibilities with our all-in-one marvel",
    content: "Unleashing unlimited free meetings, direct calling, Chat with live translation, and a Web 3 wallet in one seamless app.\n" +
        "Elevate your experience and embrace the future, effortlessly at your fingertips!",
    headingSlug1: "* Download the app for FREE",
    headingSlug: "* Available on iPhone, iPad and all Android devices",
    headingcoin: "Buy Woooo Coin Now",
    playImg: "/img/google-play.png",
    appleImg: "/img/app-store.png",
    coinImg: "/img/coin.gif",
    heroThumb: "/img/welcome_mockup_2.png"
}

class HeroSection extends Component {
    state = {
        data: {}
    }
    componentDidMount() {
        this.setState({
            data: initData
        })
    }
    render() {
        return (
            <section id="home" className="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
                {/*<div className='roatating-element'>*/}
                {/*    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"*/}
                {/*         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"*/}
                {/*         className="feather feather-phone">*/}
                {/*        <path*/}
                {/*            d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>*/}
                {/*    </svg>*/}
                {/*</div>*/}
                <div className="container">
                    <Lottie className='lottie_class' style={{ position: 'absolute', width: 600, right: 0, top: 0, transform: 'scaleX(-1)' }} animationData={Animation1} loop={true} />
                    <div className="row align-items-center">
                        <div className="col-12 col-md-5 order-2 order-md-1">
                            {/* Welcome Thumb */}
                            <div data-aos="zoom-out" style={{ position: "relative", zIndex: 999 }} className="welcome-thumb mx-auto">
                                <div className="welcome-thumb-video" style={{
                                    position: 'relative',
                                    width: 298,
                                    height: 600,
                                    overflow: 'hidden',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 50,
                                    background: '#0088C0'
                                }}>
                                    <video
                                        autoPlay
                                        loop
                                        muted
                                        playsInline
                                        src='/img/woooo.mp4'
                                        style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', height: '90%', objectFit: 'contain', borderRadius: 30 }}
                                    >
                                    </video>
                                    <div style={{
                                        backgroundImage: "url('/img/iphone.png')",
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        position: 'relative',
                                        width: '100%',
                                        height: '100%',
                                        zIndex: 1,
                                        borderRadius: 40,
                                    }}></div>
                                </div>
                            </div>



                        </div>
                        {/* Welcome Intro Start */}
                        <div style={{ position: 'relative' }} data-aos="fade-up" className="col-12 col-md-6 order-1 order-md-2">
                            <div className="welcome-intro px-0 px-lg-4">
                                <h1 className="text-white font-italic mb-2 mb-md-3">{this.state.data.heading}</h1>
                                <h3 className="text-white text-capitalize">{this.state.data.headingTwo}</h3>
                                <p className="text-white mt-3 mb-4">{this.state.data.content}</p>
                                <span className="d-inline-block text-white fw-3 font-italic">{this.state.data.headingSlug1}</span>

                                {/* Store Buttons */}
                                <div className="button-group store-buttons d-flex">
                                    <a title="Download From Play Store" onClick={() => {
                                        logEvent(analytics, 'android_app_download_button_clicked', {
                                            source: window.location.hostname || 'woooo.world',
                                            timestamp: new Date().toISOString()
                                        });
                                        window.open('https://play.google.com/store/apps/details?id=com.wgroup.woooo_app', '_blank')
                                    }}>
                                        <img src={this.state.data.playImg} alt="" />
                                    </a>

                                    <a title="Download From App Store" onClick={() => {
                                        logEvent(analytics, 'ios_app_download_button_clicked', {
                                            source: window.location.hostname || 'woooo.world',
                                            timestamp: new Date().toISOString()
                                        });
                                        window.open('https://apps.apple.com/pk/app/woooo/id6476143922', '_blank')
                                    }}>
                                        <img src={this.state.data.appleImg} alt="" />
                                    </a>
                                </div>
                                <span className="d-inline-block text-white fw-3 font-italic mt-3">{this.state.data.headingSlug}</span>

                                <div className="button-group store-buttons d-flex">
                                    <a title="Buy WOOOO Coin Now" onClick={() => {
                                        logEvent(analytics, 'android_app_download_button_clicked', {
                                            source: window.location.hostname || 'woooo.world',
                                            timestamp: new Date().toISOString()
                                        });
                                        window.open('https://woooocompany.mysellix.io/product/woooo', '_blank')
                                    }}>
                                        <img src={this.state.data.coinImg} alt="" />

                                        <span className="d-inline-block text-white fw-3 font-italic mt-3">{this.state.data.headingcoin}</span>

                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HeroSection;
