import React, {Component} from 'react';
import Header from '../HeaderSection/Header';
import Breadcrumb from '../Blogs/Breadcrumb';
import FooterSection from '../FooterSection/Footer';
import {Helmet} from "react-helmet";
import WOOPress1 from "./woooo-press1.png"
import WOOPress2 from "./woooo-press2.png"

class PressRelease extends Component {
    render() {
        return (
            <div>
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up"/>
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Helmet>
                        <title>Woooo - Press Release</title>
                        <link rel="canonical" href="https://woooo.world/press-release" />
                    </Helmet>
                    <Header imageData={"/img/logo-white.png"}/>
                    <Breadcrumb title="Press Release"/>
                    <div className='roadmap_classs' style={{
                        padding: '6em',
                        width: '100%',
                        maxWidth: '1440px',
                        background: '#fff',
                        borderRadius: 20,
                        position: 'relative',
                        zIndex: 9,
                        margin: "0px auto",
                        marginTop: '-6em',
                        justifyContent: "center",
                        alignItems: 'center',
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <div style={{padding: '20px'}}>
                            <div style={{textAlign: 'center'}}>
                                <img
                                    src={WOOPress1}
                                    alt="City skyline with 'WOOOO APP' text and a tagline 'Changing Online Communication Forever'"
                                    style={{width: '100%', height: 'auto', borderRadius: 12, marginBottom: 20}}
                                />
                            </div>
                            <div style={{
                                textAlign: 'center',
                                fontSize: '36px',
                                fontWeight: 'bold',
                                marginBottom: '20px',
                                lineHeight: 1
                            }}>
                                LIFE-CHANGING ONLINE COMMUNICATION WITH THE NEW WOOOO APP
                            </div>
                            <div style={{
                                textAlign: 'center',
                                fontSize: '18px',
                                fontWeight: 'bold',
                                marginBottom: '20px'
                            }}>
                                Meet the Revolutionary Woooo AI: Your One-Stop Solution for All Communication Needs
                            </div>
                            <div style={{textAlign: 'left', fontSize: '16px', marginBottom: '20px'}}>
                                <p>
                                    The landscape of online communication is about to transform forever with the
                                    introduction
                                    of Woooo, an innovative app embedded with advanced AI technology. This
                                    groundbreaking app
                                    is set to become your ultimate communication hub, offering a seamless blend of live
                                    chat,
                                    encrypted calling, and online meetings in one place.
                                </p>
                            </div>
                            <div style={{
                                textAlign: 'center',
                                fontSize: '24px',
                                fontWeight: 'bold',
                                marginBottom: '20px'
                            }}>
                                A NEW ERA OF COMMUNICATION
                            </div>
                            <div style={{textAlign: 'left', fontSize: '16px', marginBottom: '20px'}}>
                                <p>
                                    Imagine a world where language barriers no longer exist. With Woooo, you don't need
                                    to worry
                                    about sending messages to someone who speaks a different language. Simply click and
                                    send, and
                                    the recipient will receive your message in their native language. This revolutionary
                                    feature
                                    makes global communication effortless and instant.
                                </p>
                            </div>
                        </div>

                        <div style={{margin: '40px 0', width: '100%', height: 1, background: 'rgba(0,0,0,0.1)'}}/>


                        <div style={{padding: '20px'}}>
                            <div style={{textAlign: 'center'}}>
                                <img
                                    src={WOOPress2}
                                    alt="City skyline with 'WOOOO APP' text and a tagline 'Changing Online Communication Forever'"
                                    style={{width: '100%', height: 'auto', borderRadius: 12, marginBottom: 20}}
                                />
                            </div>
                                <div style={{
                                    textAlign: 'center',
                                    fontSize: '36px',
                                    fontWeight: 'bold',
                                    marginBottom: '20px',
                                    lineHeight:1
                                }}>
                                    LIFE-CHANGING ONLINE COMMUNICATION WITH THE NEW WOOOO APP
                                </div>
                                <div style={{
                                    textAlign: 'center',
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    marginBottom: '20px',
                                }}>
                                    Meet the Revolutionary Woooo AI: Your One-Stop Solution for All Communication Needs
                                </div>
                                <div style={{textAlign: 'left', fontSize: '16px', marginBottom: '20px'}}>
                                    <p>
                                        The landscape of online communication is about to transform forever with the
                                        introduction
                                        of Woooo, an innovative app embedded with advanced AI technology. This
                                        groundbreaking app
                                        is set to become your ultimate communication hub, offering a seamless blend of
                                        live chat,
                                        encrypted calling, and online meetings in one place.
                                    </p>
                                </div>
                                <div style={{
                                    textAlign: 'center',
                                    fontSize: '24px',
                                    fontWeight: 'bold',
                                    marginBottom: '20px'
                                }}>
                                    A NEW ERA OF COMMUNICATION
                                </div>
                                <div style={{textAlign: 'left', fontSize: '16px', marginBottom: '20px'}}>
                                    <p>
                                        Imagine a world where language barriers no longer exist. With Woooo, you don't
                                        need to worry
                                        about sending messages to someone who speaks a different language. Simply click
                                        and send, and
                                        the recipient will receive your message in their native language. This
                                        revolutionary feature
                                        makes global communication effortless and instant.
                                    </p>
                                </div>
                                <div style={{
                                    textAlign: 'center',
                                    fontSize: '24px',
                                    fontWeight: 'bold',
                                    marginBottom: '20px'
                                }}>
                                    LIVE TRANSLATION FOR CALLS AND MEETINGS
                                </div>
                                <div style={{textAlign: 'left', fontSize: '16px', marginBottom: '20px'}}>
                                    <p>
                                        Woooo takes communication a step further with live translation capabilities.
                                        When you call
                                        someone who speaks a different language, Woooo translates your conversation in
                                        real-time,
                                        either as text or spoken by our AI. This feature extends to online meetings,
                                        allowing you to
                                        conduct business seamlessly with colleagues who speak different languages. With
                                        Woooo,
                                        every word you speak is automatically translated for your audience, breaking
                                        down
                                        communication barriers and fostering global collaboration.
                                    </p>
                                </div>
                                <div style={{
                                    textAlign: 'center',
                                    fontSize: '24px',
                                    fontWeight: 'bold',
                                    marginBottom: '20px'
                                }}>
                                    UNMATCHED SECURITY AND PRIVACY
                                </div>
                                <div style={{textAlign: 'left', fontSize: '16px', marginBottom: '20px'}}>
                                    <p>
                                        Security and privacy are at the core of Woooo's design. Utilizing blockchain
                                        technology,
                                        Woooo ensures that all your data remains under your control. Your privacy is our
                                        top priority,
                                        and we are proud to introduce the first communication app that uses blockchain
                                        to secure
                                        your data. With Woooo, your information is your property, safeguarded with the
                                        highest
                                        standards of security.
                                    </p>
                                </div>
                                <div style={{
                                    textAlign: 'center',
                                    fontSize: '24px',
                                    fontWeight: 'bold',
                                    marginBottom: '20px'
                                }}>
                                    CONCLUSION
                                </div>
                                <div style={{textAlign: 'left', fontSize: '16px', marginBottom: '20px'}}>
                                    <p>
                                        Don't look any further for a comprehensive communication solution. Woooo
                                        combines live
                                        chat, encrypted calling, and online meetings into a single app, making it the
                                        only tool you'll
                                        need for all your communication needs. Experience the future of online
                                        communication
                                        today with Woooo.
                                    </p>
                                </div>
                        </div>

                    </div>
                    <FooterSection/>
                </div>
            </div>
        );
    }
}

export default PressRelease;
