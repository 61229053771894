import React, {Component} from 'react';
import Header from '../HeaderSection/Header';
import Breadcrumb from '../Blogs/Breadcrumb';
import FooterSection from '../FooterSection/Footer';
import token from "./token.jpeg";
import {Helmet} from "react-helmet";

class WooooCoinSection extends Component {
    render() {
        return (
            <div>
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up"/>
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Helmet>
                        <title>Woooo - Coin WOC</title>
                        <link rel="canonical" href="https://woooo.world/woooo-coin" />
                    </Helmet>
                    <Header imageData={"/img/logo-white.png"}/>
                    <Breadcrumb title="WOOOO COIN (W.O.C)"/>
                    <div style={{
                        width: '100%',
                        maxWidth: '1440px',
                        background: '#fff',
                        borderRadius: 20,
                        position: 'relative',
                        zIndex: 9,
                        margin: "0px auto",
                        marginTop: '-6em',
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column"
                    }}>

                        {/*<div style={{width:'60%'}} className="service-thumb mx-auto mobile-dex-img">*/}
                        {/*    <img src={dex} alt="dex" />*/}
                        {/*</div>*/}



                        <section style={{background:'#fff'}} className="section service-area bg-gray overflow-hidden ptb_100">
                            <div className="container">
                                <div className="row justify-content-between">
                                    <div className="col-12 col-lg-8 order-2 order-lg-1">
                                        {/* Service Text */}
                                        <div className="service-text pt-4 pt-lg-0">
                                            <h2 style={{color:"rgb(32, 166, 232)"}} className="text-capitalize mb-2">Unleashing Global Connectivity</h2>
                                            <p style={{marginTop:20}}>
                                                Woooo Coin is the beacon of utility within the Woooo network—an ultimate super app that transcends boundaries, connecting the world like never before. More than just a cryptocurrency, Woooo Coin is the key to eliminating language barriers, fostering global unity, and transforming ordinary interactions into rewarding journeys. <br/>
                                                (W.O.C) Woooo coin—the driving force within the Woooo network, propelling us towards a boundless future. Infused with vibrant energy through POS and Proof of Use, we sculpt a better tomorrow, forging a path to a brighter, more innovative existence. <br/>
                                                Turn Your Mobile Into a Mining Powerhouse with Woooo! Woooo Blockchain Unleashes Effortless W.O.C. Mining on Your Mobile! Experience the Lightning-Fast and Secure Revolution with Zero Stress on Your Phone.
                                            </p>
                                        </div>
                                        <div style={{marginTop:40}} className="service-text pt-4 pt-lg-0">
                                            <h2 style={{color:"rgb(32, 166, 232)"}} className="text-capitalize mb-2">Fearless Connections</h2>
                                            <p style={{marginTop:20}}>
                                                With Woooo, fearlessly connect with anyone, regardless of the language they speak. Woooo Coin empowers users to break through communication barriers and engage in seamless interactions with people from diverse backgrounds. It's the catalyst for a new era of communication, making connections that transcend linguistic differences. <br/>

                                                Connect, chat, and call with WOOOO—the enchantment unfolds when your phone taps into our dazzling blockchain network. Woooo Coin transforms everyday conversations into mining adventures, where each interaction becomes a rewarding journey. As you engage with the Woooo app, you're not just communicating; you're actively participating in the Proof-of-Use blockchain concept, mining Woooo Coin with every tap. <br/>

                                                In essence, Woooo Coin goes beyond being just a cryptocurrency; it's a revolutionary force in the world of communication and connectivity. It turns conversations into adventures, breaks down language barriers, and fuels a dynamic blockchain network.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 order-1 order-lg-2 d-none d-lg-block get-start-image">
                                        {/* Service Thumb */}
                                        <div style={{width:250}} className="service-thumb mx-auto">
                                            <img src={'/img/woooo-coin.png'} alt="dex" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section style={{background:'#fff',marginBottom:100}} className="section service-area bg-gray overflow-hidden">
                            <div className="container">
                                <div className="row justify-content-between">
                                    <div className="col-12 col-lg-6 order-2 order-lg-1">
                                        {/* Service Text */}
                                        <div className="service-text pt-4 pt-lg-0">
                                            <h2 style={{color:"rgb(32, 166, 232)"}} className="text-capitalize mb-2">WOOOO TOKENOMICS</h2>
                                            <p style={{marginTop:20}}>
                                                Crafted for Brilliance! Over 60% securely staked in our mining pool,
                                                ensuring scarcity and potential value surge. A strategic 20% reserved for
                                                public sales, promising value ascent with our expanding user community.
                                                Experience the magic with an AI-powered communication app, where
                                                Woooo coin becomes the key to unlock a world of innovation and endless
                                                possibilities!
                                            </p>

                                            <h4  style={{marginTop:26,color:"rgb(32, 166, 232)"}}>
                                                Embark on Tomorrow's
                                                Triumph with Woooo Coin—
                                                Poised to Set New Heights in Digital
                                                currency,
                                                Pioneering a Path Beyond Challenge!
                                            </h4>
                                        </div>

                                    </div>
                                    <div className="col-12 col-lg-5 order-1 order-lg-2 d-none d-lg-block get-start-image">
                                        {/* Service Thumb */}
                                        <div style={{width:550}} className="service-thumb mx-auto">
                                            <img src={token} alt="token" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                    <FooterSection/>
                </div>
            </div>
        );
    }
}

export default WooooCoinSection;
